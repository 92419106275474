<template>
  <div class="root">
    <router-view></router-view>
  </div>
</template>

<script setup>
</script>

<style lang="scss">
@import "/src/assets/fonts/stylesheet.scss";
* {
  font-family: 'GT Eesti Pro Display', sans-serif;
}

body, html {
  padding: 0;
  margin: 0;
}
.root {
  max-width: 430px;
  margin: 0 auto;
}
</style>

<style lang="scss" scoped>
.root {
  &__header {
    margin: 32px 0 40px 0;
  }
  &__footer {
    padding: 15px 0;
  }
}

</style>
