<template>
  <div class="input-block">
    <svg v-if="UIType === 'search'" class="input-block__icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.2" d="M20 18.954L14.4133 13.3673C15.7558 11.7556 16.4253 9.68835 16.2825 7.59562C16.1396 5.50288 15.1954 3.54579 13.6464 2.13146C12.0973 0.717125 10.0626 -0.0455437 7.96554 0.00210549C5.86847 0.0497547 3.87051 0.904053 2.38728 2.38728C0.904053 3.87051 0.0497547 5.86847 0.00210549 7.96554C-0.0455437 10.0626 0.717125 12.0973 2.13146 13.6464C3.54579 15.1954 5.50288 16.1396 7.59562 16.2825C9.68835 16.4253 11.7556 15.7558 13.3673 14.4133L18.954 20L20 18.954ZM1.50598 8.16382C1.50598 6.84703 1.89645 5.5598 2.62803 4.46492C3.3596 3.37004 4.39941 2.51669 5.61598 2.01277C6.83254 1.50886 8.17121 1.37701 9.46271 1.6339C10.7542 1.8908 11.9405 2.5249 12.8716 3.45601C13.8028 4.38713 14.4369 5.57345 14.6937 6.86494C14.9506 8.15644 14.8188 9.49511 14.3149 10.7117C13.811 11.9282 12.9576 12.9681 11.8627 13.6996C10.7678 14.4312 9.48062 14.8217 8.16382 14.8217C6.39865 14.8197 4.70634 14.1176 3.45818 12.8695C2.21001 11.6213 1.50793 9.92899 1.50598 8.16382Z" fill="black"/>
    </svg>
    <input
      :name="key"
      class="input-block__input"
      :type="type"
      @input="updateValue"
      :placeholder="placeholder"
      :required="required"
    >
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from "vue"

defineProps({
  modelValue: [String, Number],
  type: {
    type: String,
    default: "text"
  },
  UIType: {
    type: String,
    default: ""
  },
  placeholder: {
    type: [String, Number],
    default: ""
  },
  required: {
    type: Boolean,
    default: false
  },
  label: {
    type: String
  }
})

const key = Math.floor(Math.random() * 1000)

const emit = defineEmits(['update:modelValue'])

function updateValue(event) {
  emit('update:modelValue', event.target.value)
}
</script>

<style lang="scss" scoped>
.input-block {
  background: #EEEEEE;
  border-radius: 20px;
  font-weight: 500;
  padding: 15px;
  border-radius: 20px;
  color: #545454;
  display: flex;
  &__icon {
    margin-bottom: -4px;
    margin-right: 15px;
  }
  &__input {
    outline: none;
    border: none;
    background: transparent;
    flex-grow: 1;
    font-size: 15px;
    &::placeholder { 
      color: #545454;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #545454;
    }

    &::-ms-input-placeholder {
      color: #545454;
    }
  }
}
</style>