import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import MButton from "@/UI/Button"
import MChip from "@/UI/Chip"
import MInput from "@/UI/Input"
import MTabs from "@/UI/Tabs"

import router from "ROUTER/"

import Settings from "/src/assets/settings-exapmle.json"

(async () => {

  // Создание приложения
  const app = createApp(App)

  // Регистрация объекта настроек для всего приложения
  app.provide("Settings", Settings)
  app.provide("PartnerId", '63d961b29883e9a2ccd6ff6b')

  // Глобальная регистрация UI rjvgjytynjd
  app.component("MButton", MButton)
  app.component("MChip", MChip)
  app.component("MInput", MInput)
  app.component("MTabs", MTabs)

  // Используем роутер, как только он будет готов, маунтим приложение
  app.use(router)
  router.isReady().then(() => {
    app.mount('#app')
  })
})()

